<template lang="pug">
include ../../../configs/template.pug
div.d-flex.flex-wrap.mt-3.text-left
  div.w-100
    +field-validation('body.number', 'number', '["required"]')
  div(v-if="sailorDocument.is_international").w-100
    +field-validation('body.other_medical_institution', 'medicalInstitution', '["required"]')
  div(v-else).w-100
    +select-validation('body.medical_institution', 'medicalInstitutionsList', 'medicalInstitution', '"value"', '["required"]')(
      @change="body.doctor = null")
  div(v-if="sailorDocument.is_international").w-100
    +field-validation('body.other_doctor', 'doctor', '["required"]')
  div(v-else).w-100
    +select-validation('body.doctor', 'doctorsByMedicalInstitutionList', 'doctor', '"FIO"', '["required"]')
  div.w-100
    +select-validation('body.position', 'medicalPositionsList', 'position', 'labelName', '["required"]')
  div.w-100
    +select-validation('body.limitation', 'medicalLimitationsList', 'limitation', 'labelName', '["required"]')
  div.w-50
    +date-picker-validation('body.date_start', 'dateIssue', 'dateIssuedObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" min="1900-01-01")
  div.w-50
    +date-picker-validation('body.date_end', 'dateTermination', 'dateTerminateObject', '["required", "minValue", "maxValue"]')(
      :min="sailorDocument.date_start" max="2200-12-31")
  FileDropZone(ref="mediaContent" v-if="checkAccess('sailorMedical', 'add_file')").w-100
  div.w-100.d-flex.justify-center.mt-3
    v-btn(@click="saveMedicalCertificate" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { sailorMedicalCertificate } from '@/mixins/validationRules'
import { checkAccess } from '@/mixins/permissions'
import { clearBody } from '@/mixins/main'
import { SUCCESS_CODE } from '@/configs/constants'
const initBody = () => ({
  number: null,
  position: null,
  limitation: null,
  other_limitation: null,
  medical_institution: null,
  other_medical_institution: null,
  doctor: null,
  other_doctor: null,
  date_start: null,
  date_end: null
})
export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      isLoading: false,
      files: [],
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['doctorsByMedInstitutionID', 'doctorByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      medicalInstitutionsList: state => state.directory.medInstitution.filter(el => !el.is_disabled),
      medicalPositionsList: state => state.directory.positionMedical,
      medicalLimitationsList: state => state.directory.limitations
    }),
    doctorsByMedicalInstitutionList () {
      return this.doctorsByMedInstitutionID(this.body.medical_institution)
    },
    dateIssuedObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateTerminateObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  mounted () {
    if (this.$route.params.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.sailorDocument[key]))
      this.body.medical_institution = this.doctorByID(this.body.doctor).medical_institution
    }
  },
  validations () { return sailorMedicalCertificate(this) },
  methods: {
    ...mapActions(['setMedicalCertificate', 'updateMedicalCertificateById', 'getMedicalCertificateByID']),

    async saveMedicalCertificate () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true

      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'medical_doc'
        }
      }
      const response = await this[this.$route.params?.documentID ? 'updateMedicalCertificateById' : 'setMedicalCertificate'](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        if (!this.$route.documentID) { // If called adding component
          this.$notification.success('addedMedDoc')
          this.$parent.isViewAddSlot = false
          this.$v.$reset()
        }
        if (this.$route.query?.viewEditBlock) this.$router.replace({ query: {} })
      }
      this.isLoading = false
    }
  }
}
</script>
